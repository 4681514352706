<template>
  <div class="edit-industry-item-relations position-relative">
    <progress-linear v-if="loading"/>
    <h2>{{__('component.crafting_calculator.craftable_in')}}</h2>
    <template v-if="itemId !== null">
      <table class="editable-table">
        <tr v-for="(industryItemRelation, index) in industryItemRelations" :key="'industryItemRelation_'+index">
          <td class="editable-table__status">
            <icon :icon="'asterisk'" v-if="isNew(industryItemRelation)"/>
            <icon :icon="'bin'" v-if="isDeleted(industryItemRelation)"/>
          </td>
          <td class="editable-table__icon"><img v-if="industryItemRelation.industryItem" :src="industryItemRelation.industryItem.icon" alt="-"/></td>
          <td><template v-if="industryItemRelation.industryItem">
            {{ industryItemRelation.industryItem.fullName }}
          </template></td>
          <td class="editable-table__button" @click="toggleDeleted(industryItemRelation)">
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/bin.svg" alt="x"/>
          </td>
        </tr>
      </table>
      <div class="layout">
        <div class="flex xs6 mt">
          <div class="btn btn--success" :loading="loading" :disabled="!hasChanges()" @click="saveRecipes"
               v-slashes>{{
            __('common.save_changes') }}
          </div>
        </div>
        <div class="flex xs6 mt">
          <div class="btn btn--info" :loading="loading" @click="showItemBrowser" v-slashes>
            {{__('common.add')}}
          </div>
        </div>
      </div>
    </template>
    <p v-else>{{__('component.ingame.edit_item.edit_recipes.save_first')}}</p>
    <item-browser ref="itemBrowser" @itemSelected="addItem"/>
  </div>
</template>

<script>
  import apiSecured from '../../../api/secured';
  import { notificationsMixin } from '../../../mixins/notifications';
  import { localizationMixin } from '../../../mixins/localization';
  import ItemBrowser from "../Common/ItemBrowser";

  export default {
    components: { ItemBrowser },
    mixins: [notificationsMixin, localizationMixin],
    data: () => ({
      loading: false,
      industryItemRelations: [],
      inProgress: 0,
      rerenderKey: 'no-set'
    }),
    props: ['itemId'],
    watch: {
      itemId() {
        this.refresh();
      }
    },
    created() {
      this.refresh();
    },
    methods: {
      hasChanges() {
        return this.industryItemRelations.filter((industryItemRelation) => {
          return this.isNew(industryItemRelation) || this.isChanged(industryItemRelation) || this.isDeleted(industryItemRelation);
        }).length > 0;
      },
      refresh() {
        this.loading = true;
        this.industryItemRelations = [];
        if (this.itemId === null) {
          this.loading = false;
          return;
        }
        apiSecured.get('/ingame/items/industryitemrelation/by-craftable-item/' + this.itemId).then(async (res) => {
          this.industryItemRelations = res.data;
          await this.enrichRecords();
          this.loading = false;
          this.showInfoNotification(this.__('common.recipes_loaded'))
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async enrichRecords() {
        // const industryItemIds = this.industryItemRelations.filter(record => record.industryItemId !== null).map(record => record.industryItemId);
        //
        // if (industryItemIds.length > 0) {
        //   await apiSecured.get(`/ingame/items/item/(${industryItemIds.join(',')})`, {
        //     params: {
        //       fields: 'id,icon,name'
        //     },
        //   }).then((res) => {
        //     this.industryItemRelations = this.industryItemRelations.map(record => {
        //       record.industryItem = res.data.find(resRecord => resRecord.id === record.industryItemId);
        //       return record;
        //     });
        //     this.showInfoNotification('Industry items have been reloaded');
        //   }).catch((error) => {
        //     this.showErrorNotifications(error)
        //   });
        // }
      },
      registerChange(industryItemRelation) {
        if (!this.isNew(industryItemRelation)) {
          industryItemRelation.changed = true;
        }
      },
      isNew(industryItemRelation) {
        return industryItemRelation.hasOwnProperty('new') && industryItemRelation.new;
      },
      isChanged(industryItemRelation) {
        return industryItemRelation.hasOwnProperty('changed') && industryItemRelation.changed;
      },
      isDeleted(industryItemRelation) {
        return industryItemRelation.hasOwnProperty('deleted') && industryItemRelation.deleted;
      },
      showItemBrowser() {
        this.$refs.itemBrowser.showDialog();
      },
      addItem(industryItem) {
        if (!this.industryItemRelations.find((industryItemRelation) => industryItemRelation.industryItemId === industryItem.id)) {
          this.industryItemRelations.push(
            {
              craftableItemId: this.itemId,
              industryItemId: industryItem.id,
              industryItem: industryItem,
              amount: 0,
              new: true
            }
          )
        }
      },
      toggleDeleted(industryItemRelation) {
        if (this.isNew(industryItemRelation)) {
          this.industryItemRelations = this.industryItemRelations.filter((fIndustryItemRelation) => fIndustryItemRelation !== industryItemRelation);
          this.$forceUpdate();
        } else {
          industryItemRelation.deleted = !this.isDeleted(industryItemRelation);
          this.$forceUpdate();
        }
      },
      getPrependIcon(industryItemRelation) {
        if (this.isDeleted(industryItemRelation)) {
          return 'bin'
        } else if (this.isChanged(industryItemRelation)) {
          return 'pencil'
        } else if (this.isNew(industryItemRelation)) {
          return 'asterisk'
        }
        return null;
      },
      saveRecipes() {
        this.industryItemRelations.forEach((industryItemRelation) => {
          let endpoint = '/ingame/items/industryitemrelation';
          let event = null;
          let method = null;
          let payload = null;
          if (this.isNew(industryItemRelation)) {
            event = 'industryItemRelationCreated';
            method = apiSecured.post;
            payload = {
              craftableItemId: this.itemId,
              industryItemId: industryItemRelation.industryItem.id
            };
          } else if (this.isDeleted(industryItemRelation)) {
            endpoint = '/ingame/items/industryitemrelation/' + industryItemRelation.id;
            event = 'industryItemRelationDeleted';
            method = apiSecured.delete;
            payload = {};
          } else {
            return;
          }
          this.loading = true;
          this.inProgress++;
          method(endpoint, payload).then((res) => {
            this.showSuccessNotification(this.__('common.saved'));
            this.$emit(event);
            this.inProgress--;
            if (this.inProgress <= 0) {
              this.loading = false;
              this.inProgress = 0;
              this.refresh()
            }
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
            this.inProgress--;
            if (this.inProgress <= 0) {
              this.loading = false;
              this.inProgress = 0;
              this.refresh()
            }
          });
        })
      }
    }
  }
</script>