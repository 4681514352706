<template>
  <div class="original-item">
    <h2>{{__('component.ingame.edit_item.original_item.title')}}</h2>
    <table class="object-table" v-if="originalItem !== undefined && originalItem.id !== null">
      <tr v-for="property in Object.keys(originalItem)">
        <td>{{property}}</td>
        <td>{{originalItem[property]}}</td>
      </tr>
<!--      <tr>-->
<!--        <td>{{__('entity.common.icon')}}:</td>-->
<!--        <td><img :src="originalItem.icon" alt="no icon"/></td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.common.id')}}:</td>-->
<!--        <td>{{ originalItem.id}}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.common.name')}}:</td>-->
<!--        <td>{{ originalItem.name }}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.common.description')}}:</td>-->
<!--        <td>{{ originalItem.description }}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.common.subdescription')}}:</td>-->
<!--        <td>{{ originalItem.subdescription }}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.common.group')}}:</td>-->
<!--        <td v-if="originalItem.groupId">{{ originalItem.groupId }}</td>-->
<!--        <td v-else>-</td>-->
<!--      </tr>-->

<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.bot_price')}}:</td>-->
<!--        <td>{{ originalItem.botPrice }}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.buy_price')}}:</td>-->
<!--        <td>{{ originalItem.buyPrice }}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.crafting_time')}}:</td>-->
<!--        <td>{{ originalItem.craftingTime }} seconds</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.height')}}:</td>-->
<!--        <td>{{ originalItem.height }} meters</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.layer')}}:</td>-->
<!--        <td>{{ originalItem.layer }}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.length')}}:</td>-->
<!--        <td>{{ originalItem.length }} meters</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.mass')}}:</td>-->
<!--        <td>{{ originalItem.mass }} kg</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.maximumThrust')}}:</td>-->
<!--        <td>{{ originalItem.maximumThrust }} newton</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.minimum_crafting_amount')}}:</td>-->
<!--        <td>{{ originalItem.minimumCraftingAmount }} seconds</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.sell_price')}}:</td>-->
<!--        <td>{{ originalItem.sellPrice }}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.common.tier')}}:</td>-->
<!--        <td>{{ originalItem.tier }}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.common.volume')}}:</td>-->
<!--        <td>{{ originalItem.volume }} liters</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.warmupTime')}}:</td>-->
<!--        <td>{{ originalItem.warmupTime }} seconds</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.width')}}:</td>-->
<!--        <td>{{ originalItem.width }} meters</td>-->
<!--      </tr>-->
<!--      -->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.lift_drag_ratio')}}:</td>-->
<!--        <td>{{ originalItem.liftDragRatio }} deg</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.angle_control')}}:</td>-->
<!--        <td>{{ originalItem.angleControl }} deg</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.range')}}:</td>-->
<!--        <td>{{ originalItem.range }} meters</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.repair')}}:</td>-->
<!--        <td>{{ originalItem.repair }} hp</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.works_in_space')}}:</td>-->
<!--        <td>{{ originalItem.worksInSpace }}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.works_in_atmosphere')}}:</td>-->
<!--        <td>{{ originalItem.worksInAtmosphere }}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.ingame.item.required_item_for_crafting')}}:</td>-->
<!--        <td>-->
<!--          <template v-if="originalItem.requiredItemForCraftingId">-->
<!--            {{ originalItem.requiredItemForCraftingId }}-->
<!--          </template>-->
<!--        </td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.common.created_at')}}:</td>-->
<!--        <td>{{ originalItem.createdAt }}</td>-->
<!--      </tr>-->
<!--      <tr>-->
<!--        <td>{{__('entity.common.updated_at')}}:</td>-->
<!--        <td>{{ originalItem.updatedAt }}</td>-->
<!--      </tr>-->
    </table>
    <p v-else>{{__('component.ingame.edit_item.original_item.save_first')}}</p>
  </div>
</template>

<script>
  import { localizationMixin } from '../../../mixins/localization';

  export default {
    mixins: [localizationMixin],
    props: ['originalItem']
  }
</script>