<template>
  <div class="edit-recipes position-relative" :key="rerenderKey">
    <progress-linear v-if="loading"/>
    <h2>{{__('component.crafting_calculator.ingredients')}}</h2>
    <template v-if="itemId !== null">
      <table class="editable-table">
        <tr v-for="(recipe, index) in recipes" :key="'recipe_'+index">
          <td><img v-if="recipe.ingredientItem" :src="recipe.ingredientItem.icon" alt="-"/></td>
          <td>
            <template v-if="recipe.ingredientItem">{{ recipe.ingredientItem.fullName }}</template>
          </td>
          <td>
            <text-field
                v-if="recipe.ingredientItem"
                v-model="recipe.amount"
                :label="__('component.crafting_calculator.amount')"
                :type="'number'"
                maxlength="10" min="0"
                :hide-details="true"
                :prepend-icon="getPrependIcon(recipe)"
                @input="registerChange(recipe)"
            />
          </td>
          <td class="editable-table__button" @click="toggleDeleted(recipe)">
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/bin.svg" alt="x"/>
          </td>
        </tr>
      </table>
      <div class="layout">
        <div class="flex xs6 mt">
          <div class="btn btn--success" :loading="loading" :disabled="!hasChanges()" @click="saveRecipes"
               v-slashes>{{
            __('common.save_changes') }}
          </div>
        </div>
        <div class="flex xs6 mt">
          <div class="btn btn--info" :loading="loading" @click="showIngredientBrowser" v-slashes>
            {{__('component.ingame.edit_item.edit_recipes.add_ingredient')}}
          </div>
        </div>
      </div>
    </template>
    <p v-else>{{__('component.ingame.edit_item.edit_recipes.save_first')}}</p>
    <ingredient-browser ref="ingredientBrowser" @itemSelected="addIngredient"/>
  </div>
</template>

<script>
  import apiSecured from '../../../api/secured';
  import { notificationsMixin } from '../../../mixins/notifications';
  import { rerenderMixin } from "../../../mixins/rerender";
  import { localizationMixin } from '../../../mixins/localization';
  import IngredientBrowser from "../Common/ItemBrowser";

  export default {
    components: { IngredientBrowser },
    mixins: [notificationsMixin, rerenderMixin, localizationMixin],
    data: () => ({
      loading: false,
      recipes: [],
      inProgress: 0,
      rerenderKey: 'no-set'
    }),
    props: ['itemId'],
    watch: {
      itemId() {
        this.refresh();
      }
    },
    created() {
      this.refresh();
    },
    methods: {
      hasChanges() {
        return this.recipes.filter((recipe) => {
          return this.isNew(recipe) || this.isChanged(recipe) || this.isDeleted(recipe);
        }).length > 0;
      },
      refresh() {
        this.loading = true;
        this.recipes = [];
        if (this.itemId === null) {
          this.loading = false;
          return;
        }
        apiSecured.get('/ingame/items/recipeingredient/by-item/' + this.itemId).then(async (res) => {
          this.recipes = res.data;
          await this.enrichRecords();
          this.loading = false;
          this.showInfoNotification(this.__('common.recipes_loaded'))
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async enrichRecords() {
        // const ingredientIds = this.recipes.filter(record => record.ingredientItemId !== null).map(record => record.ingredientItemId);
        //
        // if (ingredientIds.length > 0) {
        //   await apiSecured.get(`/ingame/items/item/(${ingredientIds.join(',')})`, {
        //     params: {
        //       fields: 'id,icon,name'
        //     },
        //   }).then((res) => {
        //     this.recipes = this.recipes.map(record => {
        //       record.ingredientItem = res.data.find(resRecord => resRecord.id === record.ingredientItemId);
        //       return record;
        //     });
        //     this.showInfoNotification('Ingredient items have been reloaded');
        //   }).catch((error) => {
        //     this.showErrorNotifications(error)
        //   });
        // }
      },
      registerChange(recipe) {
        if (!this.isNew(recipe)) {
          recipe.changed = true;
          // this.forceRerender();
        }
      },
      isNew(recipe) {
        return recipe.hasOwnProperty('new') && recipe.new;
      },
      isChanged(recipe) {
        return recipe.hasOwnProperty('changed') && recipe.changed;
      },
      isDeleted(recipe) {
        return recipe.hasOwnProperty('deleted') && recipe.deleted;
      },
      showIngredientBrowser() {
        this.$refs.ingredientBrowser.showDialog();
      },
      addIngredient(ingredientItem) {
        if (!this.recipes.find((recipe) => recipe.ingredientItemId === ingredientItem.id)) {
          this.recipes.push(
            {
              recipeItemId: this.itemId,
              ingredientItemId: ingredientItem.id,
              ingredientItem: ingredientItem,
              amount: 0,
              new: true
            }
          )
        }
      },
      toggleDeleted(recipe) {
        if (this.isNew(recipe)) {
          this.recipes = this.recipes.filter((fRecipe) => fRecipe !== recipe);
          this.forceRerender();
        } else {
          recipe.deleted = !this.isDeleted(recipe);
          this.forceRerender();
        }
      },
      getPrependIcon(recipe) {
        if (this.isDeleted(recipe)) {
          return 'bin'
        } else if (this.isChanged(recipe)) {
          return 'pencil'
        } else if (this.isNew(recipe)) {
          return 'asterisk'
        }
        return null;
      },
      saveRecipes() {
        this.recipes.forEach((recipe) => {
          let endpoint = '/ingame/items/recipeingredient';
          let event = null;
          let method = null;
          let payload = null;
          if (this.isNew(recipe)) {
            event = 'recipeCreated';
            method = apiSecured.post;
            payload = {
              recipeItemId: this.itemId,
              ingredientItemId: recipe.ingredientItem.id,
              amount: recipe.amount,
            };
          } else if (this.isChanged(recipe)) {
            endpoint = '/ingame/items/recipeingredient/' + recipe.id;
            event = 'recipeUpdated';
            method = apiSecured.patch;
            payload = [{
              op: 'replace',
              path: `/amount`,
              value: recipe.amount
            }];
          } else if (this.isDeleted(recipe)) {
            endpoint = '/ingame/items/recipeingredient/' + recipe.id;
            event = 'recipeDeleted';
            method = apiSecured.delete;
            payload = {};
          } else {
            return;
          }
          this.loading = true;
          this.inProgress++;
          method(endpoint, payload).then((res) => {
            this.showSuccessNotification(this.__('common.saved'));
            this.$emit(event);
            this.inProgress--;
            if (this.inProgress <= 0) {
              this.loading = false;
              this.inProgress = 0;
              this.refresh()
            }
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
            this.inProgress--;
            if (this.inProgress <= 0) {
              this.loading = false;
              this.inProgress = 0;
              this.refresh()
            }
          });
        })
      }
    }
  }
</script>