<template>
  <div class="ingredient-browser">
    <my-dialog :show="show" @close="close" :max-width="'400px'">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.ingame.common.item_browser.title')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <progress-linear v-if="loading" :indeterminate="true"/>
        <div class="card__content">
          <text-field
              v-model="searchTerm"
              :label="__('common.search_term')"
              maxlength="50"
              :hide-details="true"
              @keyup:enter="refresh"
          />
          <div class="selection-list" v-if="items.length > 0">
            <div class="selection-list__item" v-for="item in items" v-slashes @click="emitSelection(item)">
              <div class="selection-list__item-icon">
                <img :src="item.icon" alt="-"/>
              </div>
              <div class="selection-list__item-title">
                {{ item.fullName }}
              </div>
            </div>
          </div>
          <p class="mt" v-else>{{__('component.ingame.common.item_browser.select_item_first')}}</p>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../../api/secured';
  import { notificationsMixin } from '../../../mixins/notifications';
  import { localizationMixin } from '../../../mixins/localization';

  export default {
    mixins: [notificationsMixin, localizationMixin],
    data: () => ({
      show: false,
      loading: false,
      items: [],
      searchTerm: ''
    }),
    methods: {
      refresh(item) {
        if (this.searchTerm.length < 3) {
          Swal.fire({
            type: 'warning',
            text: this.__('common.min_three_chars')
          });
          return;
        }
        this.loading = true;
        this.items = [];
        apiSecured.get('/ingame/items/item', {
          params: {
            searchQuery: this.searchTerm,
            orderBy: `name ${this.sortDesc ? 'desc' : 'asc'}`,
          }
        }).then((res) => {
          this.items = res.data;
          this.loading = false;
          this.showInfoNotification(this.__('common.items.loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      showDialog() {
        this.show = true;
      },
      close() {
        this.show = false;
      },
      emitSelection(item) {
        this.$emit('itemSelected', item);
        this.show = false;
      }
    }
  }
</script>