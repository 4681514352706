<template>
  <div class="edit-item">
    <my-dialog :show="show" @close="close" v-if="originalRecord && record">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.ingame.edit_item.title')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
<!--            <div class="flex xs3">-->
<!--              <original-item :original-item="originalRecord"/>-->
<!--            </div>-->
            <div class="flex xs5">
              <h2>{{__('common.edit_properties')}}</h2>
              <div class="layout layout--wrap">
          
                <div class="flex xs12 mt">
                  <text-field
                          v-model="record.name"
                          :label="__('entity.common.name')"
                          :error-messages="errors.name"
                          maxlength="50"
                          :prepend-icon="isChanged('name') ? 'pencil' : null"
                          @input="registerChange('name')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{ __('entity.common.group') }}</label>
                  <select-reference
                          v-model="record.groupId"
                          :endpoint="'/ingame/items/group'"
                          :nothing-selected-message="__('common.no_group_selected')"
                          :prepend-icon="isChanged('groupId') ? 'pencil' : ''"
                          @input="registerChange('groupId')"
                  />
                </div>
                <div class="flex xs12">
                  <text-field
                      v-model="record.icon"
                      :label="__('entity.common.icon')"
                      :error-messages="errors.icon"
                      :prepend-icon="isChanged('icon') ? 'pencil' : null"
                      @input="registerChange('icon')"
                  />
                </div>
                <div class="flex xs12">
                  <text-area
                      v-model="record.description"
                      :label="__('entity.common.description')"
                      :error-messages="errors.description"
                      :prepend-icon="isChanged('description') ? 'pencil' : null"
                      @input="registerChange('description')"
                  />
                </div>
                
                <!-- <hr style="width: 100%"/> -->
                
                <template v-for="key in Object.keys(record)">
                  <div v-if="!excludedAlways.includes(key) && excludedForNumericFields.includes(key)" class="flex xs12" :key="key">
                    <text-field
                        v-model="record[key]"
                        :label="key"
                        :error-messages="errors[key]"
                        :prepend-icon="isChanged(key) ? 'pencil' : null"
                        @input="registerChange(key)"
                    />
                  </div>
                </template>
                
                <!-- <hr style="width: 100%"/> -->
                
                <template v-for="key in Object.keys(record)">
                  <div v-if="!excludedAlways.includes(key) && !excludedForNumericFields.includes(key)" class="flex xs4" :key="key">
                    <text-field
                        v-model="record[key]"
                        :label="key"
                        type="number"
                        :error-messages="errors[key]"
                        :prepend-icon="isChanged(key) ? 'pencil' : null"
                        @input="registerChange(key)"
                    />
                  </div>
                </template>



                <div class="flex xs12">
                  <my-switch
                      v-model="record.activateThrustAlongGravityByDefault"
                      label="activateThrustAlongGravityByDefault"
                      :append-icon="isChanged('activateThrustAlongGravityByDefault') ? 'pencil' : null"
                      @input="registerChange('activateThrustAlongGravityByDefault')"
                  ></my-switch>
                </div>
                <div class="flex xs12">
                  <my-switch
                      v-model="record.worksInAtmosphere"
                      label="worksInAtmosphere"
                      :append-icon="isChanged('worksInAtmosphere') ? 'pencil' : null"
                      @input="registerChange('worksInAtmosphere')"
                  ></my-switch>
                </div>
                <div class="flex xs12 mt">
                  <my-switch
                      v-model="record.worksInSpace"
                      label="worksInSpace"
                      :append-icon="isChanged('worksInSpace') ? 'pencil' : null"
                      @input="registerChange('worksInSpace')"
                  ></my-switch>
                </div>
                <div class="flex xs12 mt">
                  <my-switch
                      v-model="record.hidden"
                      :label="'hidden'"
                      :append-icon="isChanged('hidden') ? 'pencil' : null"
                      @input="registerChange('hidden')"
                  ></my-switch>
                </div>
                <div class="flex xs12 mt">
                  <my-switch
                      v-model="record.needReview"
                      :label="'need_review'"
                      :append-icon="isChanged('needReview') ? 'pencil' : null"
                      @input="registerChange('needReview')"
                  ></my-switch>
                </div>
                <div class="flex xs12 mt">
                  <my-switch
                      v-model="record.isReferenceVoxel"
                      :label="'Is reference voxel'"
                      :append-icon="isChanged('isReferenceVoxel') ? 'pencil' : null"
                      @input="registerChange('isReferenceVoxel')"
                  ></my-switch>
                </div>
                <div class="flex xs12 mt">
                  <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0"
                       @click="update" v-slashes>{{ __('common.save_changes') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="flex xs7">
              <edit-recipes :item-id="record.id" ref="editRecipes"/>
              <edit-industry-item-relation :item-id="record.id" ref="editIndustryItemRelation" class="mt"/>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import { localizationMixin } from '../../mixins/localization';
  import OriginalItem from "../../components/Ingame/EditItem/OriginalItem";
  import EditRecipes from "../../components/Ingame/EditItem/EditRecipes";
  import {editFormMixin} from "../../mixins/editForm";
  import EditIndustryItemRelation from "./EditItem/EditIndustryItemRelation";

  export default {
    components: {
      EditIndustryItemRelation,
      OriginalItem,
      EditRecipes
    },
    mixins: [localizationMixin, editFormMixin],
    data: () => ({
      endpoint: '/ingame/items/item',
      emptyRecord: {
        id: null,
        botPrice: null,
        buyPrice: null,
        hidden: false,
        icon: '',
        isReferenceVoxel: false,
        layer: 0,
        needReview: false,
        scannerColor: null,
        sellPrice: 0,
        vQuantaPrice: 0,
        tier: 0,
      },
    }),
    computed: {
      excludedForNumericFields() {
        return ['scannerColor','iconInGamePath', 'key', 'scale'];
      },
      excludedAlways() {
        return ['id','hidden','icon','isReferenceVoxel','needReview','createdAt','updatedAt','name','fullName','description','hasChanges','activateThrustAlongGravityByDefault','deleted','groupId','hasWindShield','worksInAtmosphere','worksInSpace'];
      },
    },
  }
</script>